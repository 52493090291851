html {
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #4b4bfb;
  color: white;
}

hr {
  border: none;
  height: 0.05rem;
  color: rgba(255, 255, 255, 0.2); /* old IE */
  background-color: rgba(255, 255, 255, 0.2); /* Modern Browsers */
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.App {
  position: relative;
}

.profileimg {
  border-radius: 50%;
  border: 0.1rem solid rgba(255, 255, 255, 0.2);
  max-width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.workimg {
  width: 100%;
}

.worklist {
  margin-top: 2rem;
}

.worklink {
  margin-top: 1rem;
}

.backbutton {
  font-size: calc(2rem + 6 * ((100vw - 320px) / 680));
  font-weight: 900;
}

.title-contact {
  margin-bottom: 0;
}

.text-contact {
  margin-top: 0.4em;
}

/* STYLES */

h1 {
  font-size: calc(3rem + 6 * ((100vw - 320px) / 680));
  margin: 2rem 0 2rem 0;
}

h2 {
  margin: 0 0 2rem 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: rgba(255, 255, 255, 0.6);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none;
}

.nav {
  height: 50px;
  z-index: 999;
  padding: 1rem 3rem;
  max-width: 50rem;
  margin-left:auto;
  margin-right:auto;
}

.nav a {
  display: inline-block;
  margin: 0 2rem 0 0;
  padding: 1rem 0;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}

.nav a.active {
  color: rgba(255, 255, 255, 1);
}

.nav a:hover {
  color: rgba(255, 255, 255, 1);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

@media screen and (max-width: 960px) {
  .nav {
    text-align: center;
    height: 1.5rem;
    padding: 1rem 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #4b4bfb;
    border-top: 0.05rem solid rgba(255, 255, 255, 0.2);
  }
  .nav a {
    margin: 0 calc(14px + 6 * ((100vw - 320px) / 680));
    padding: 0.3rem 0;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: calc(14px + 6 * ((100vw - 320px) / 680));
  }
  h1 {
    font-size: 2rem;
    margin: 1rem 0 1rem 0;
  }
  .page {
    top: 0!important;
  }
  section {
    padding: 3rem 2rem 6rem 2rem!important;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
}
}

.page {
  position: absolute;
  left:0;
  right:0;
  top: 1rem;
  z-index: -1;
}

section {
  padding: 6rem 3rem 2rem 3rem;
  max-width: 50rem;
  margin-left:auto;
  margin-right:auto;
}

.sectionwork {
  padding: 2rem 3rem 2rem 3rem;
}

/* simple - enter transition 300ms, exit 150ms
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}
 */

/* advanced - enter transition 800ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}
/*.fade-appear p,
.fade-enter p {
  transform: translateY(-5px);
  opacity: 0;
}*/

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: opacity 300ms linear 150ms, -webkit-transform 300ms ease-out 150ms;
  transition: opacity 300ms linear 150ms, -webkit-transform 300ms ease-out 150ms;
  transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms;
  transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms, -webkit-transform 300ms ease-out 150ms;
}
/*.fade-appear-active p,
.fade-enter.fade-enter-active p {
  transform: translateY(0);
  opacity: 1;
}
.fade-appear-active p:nth-child(2),
.fade-enter.fade-enter-active p:nth-child(2) {
  transition: opacity 300ms linear 300ms, transform 300ms ease-out 300ms;
}
.fade-appear-active p:nth-child(3),
.fade-enter.fade-enter-active p:nth-child(3) {
  transition: opacity 300ms linear 400ms, transform 300ms ease-out 400ms;
}
.fade-appear-active p:nth-child(4),
.fade-enter.fade-enter-active p:nth-child(4) {
  transition: opacity 300ms linear 500ms, transform 300ms ease-out 500ms;
}*/

.fade-exit {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.fade-exit.fade-exit-active {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    -webkit-transition: opacity 150ms linear, -webkit-transform 150ms ease-in;
    transition: opacity 150ms linear, -webkit-transform 150ms ease-in;
    transition: opacity 150ms linear, transform 150ms ease-in;
    transition: opacity 150ms linear, transform 150ms ease-in, -webkit-transform 150ms ease-in;
}
@font-face {
  font-family: 'MyFontBold';
  src: url(/static/media/myfont-bold.eee232c3.eot); /* IE9 Compat Modes */
  src: local('MyFontBold'),
  url(/static/media/myfont-bold.eee232c3.eot?#iefix) format('embedded-opentype'), 
  url(/static/media/myfont-bold.5ba07350.woff2) format('woff2'), 
  url(/static/media/myfont-bold.0b8a7fbf.woff) format('woff'), 
  url(/static/media/myfont-bold.1ffc2e52.ttf) format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'MyFontRegular';
  src: url(/static/media/myfont-regular.350c2a7d.eot); /* IE9 Compat Modes */
  src: local('MyFontRegular'),
  url(/static/media/myfont-regular.350c2a7d.eot?#iefix) format('embedded-opentype'), 
  url(/static/media/myfont-regular.b1f5ae12.woff2) format('woff2'), 
  url(/static/media/myfont-regular.efa7ad5f.woff) format('woff'), 
  url(/static/media/myfont-regular.f97784bc.ttf) format('truetype'); /* Safari, Android, iOS */
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, a {
  font-family: 'MyFontBold', sans-serif;
  color: white;
}

p {
  font-family: 'MyFontRegular', sans-serif;
}

/*
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/

