@font-face {
  font-family: 'MyFontBold';
  src: url('./fonts/myfont-bold.eot'); /* IE9 Compat Modes */
  src: local('MyFontBold'),
  url('./fonts/myfont-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/myfont-bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/myfont-bold.woff') format('woff'), /* Modern Browsers */
  url('./fonts/myfont-bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'MyFontRegular';
  src: url('./fonts/myfont-regular.eot'); /* IE9 Compat Modes */
  src: local('MyFontRegular'),
  url('./fonts/myfont-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/myfont-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/myfont-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/myfont-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, a {
  font-family: 'MyFontBold', sans-serif;
  color: white;
}

p {
  font-family: 'MyFontRegular', sans-serif;
}

/*
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
